<template>
	<div>
		<apply-bonus-filters
			:username-filter="usernameFilter"
			:product-filter.sync="productFilter"
			:product-options="productOptions"
			:bonus-code-filter.sync="bonusCodeFilter"
			:fromdate-filter.sync="fromdateFilter"
			:todate-filter.sync="todateFilter"
		/>
		<b-card
			no-body
		>
			<b-table
				ref="refBonusListTable"
				class="position-relative table-white-space mb-0"
				:items="bonusLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(status)="data">
					<b-link
						:class="`text-${resolveStatus(data.item.status).variant}`"
					>
						{{ resolveStatus(data.item.status).label }}
					</b-link>
				</template>

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.createdAt ? data.item.createdAt : '') }}
				</template>

				<template #cell(action)="data">
					<b-link
              v-b-tooltip.hover.v-info
              :title="$t('Edit')"
						class="font-weight-bold text-nowrap"
						@click="showAddRoleModal(data.item.id)"
					>
						Edit
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalBonus"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<add-bonus-modal/>
	</div>
</template>

<script>
import {
  BButton, BModal, VBModal,
  BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ApplyBonusFilters from './ApplyBonusFilters.vue'

export default {
	components: {
		ApplyBonusFilters,
		AddBonusModal: () => import('./AddBonusModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
	},
	directives: {
    'b-tooltip':VBTooltip,
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
		}
	},
	methods: {
		showAddBonusModal() {
			this.$bvModal.show('modal-add-bonus')
		},
	},
	setup() {
		const bonusLists = ref([])
		const perPage = ref(20)
		const totalBonus = ref(0)
		const currentPage = ref(1)
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const usernameFilter = ref(null)
		const productFilter = ref(null)
		const bonusCodeFilter = ref(null)
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)
		const productOptions = ref([])
		const refBonusListTable = ref(null)
		const tableColumns = [
			'index',
			'username',
			'product',
			{ key: 'bonusCode', label: 'Bonus Code' },
			{ key: 'createdAt', label: 'Created At' },
			{ key: 'updatedAt', label: 'Updated At' },
			{ key: 'updatedBy', label: 'Updated By' },
			{ key: 'action', stickyColumn: true, label: 'Action', variant: 'danger' },
		]
		const dataMeta = computed(() => {
			const localItemsCount = refBonusListTable.value ? refBonusListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalBonus.value,
			}
		})
		const refetchData = () => {
			refBonusListTable.value.refresh()
		}

		watch([currentPage, perPage, usernameFilter, productFilter, bonusCodeFilter, fromdateFilter, todateFilter], () => {
			refetchData()
		})

		const statusOptions = [
			{ label: 'Active', value: 2 },
			{ label: 'inActive', value: 1 },
		]

		const resolveStatus = status => {
			if (status == 2) return { label: 'Active', variant: 'primary' }
			if (status == 1) return { label: 'inActive', variant: 'danger' }
			return { label: 'Pending', variant: 'secondary' }
		}
		return {
			tableColumns,
			perPage,
			currentPage,
			totalBonus,
			dataMeta,
			sortBy,
			isSortDirDesc,
			refBonusListTable,

			bonusLists,
			refetchData,

			usernameFilter,
			productFilter,
			productOptions,
			bonusCodeFilter,
			fromdateFilter,
			todateFilter,

			resolveStatus,
			statusOptions,

			formatDateTime,
		}
	},
}
</script>
<style>
	.b-table-sticky-column {
		right: 0!important;
	}
	td.b-table-sticky-column {
		background-color: inherit;
	}
</style>